import React,{ useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form, Button, InputGroup, FloatingLabel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import * as Icon from 'react-bootstrap-icons';
import PCMC from '../../Assets/SakalLogo.png';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData} from '../../Feature/Login/LoginSlice';



const Login = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector(state => state.loginData.userData);

useEffect(() => {
  console.log("userState", userState);
  if(userState){
  if(userState.errors === "password incorrect"){
    PassError();
    setLoader(false);
  }
  else if(userState.errors === 'No user found'){
            NoUserFound();
            setLoader(false);
    }
  else if( userState.role==="superAdmin"){
          setLoader(false);
          navigate('/dashboard');
        }
      }

}, [userState])

  const mobileErr = () => toast.error("Please enter valid mobile No");
  const PassError = () => toast.error("Please enter valid password");  
  const NoUserFound = () => toast.warning("Invalid credentials, No user found");
  const ApiError = () => toast.error("Something went wrong, Try again");



  const [mobile, setMobile] = useState('');
  const [pass, setPass] = useState('');

  const [loader,setLoader] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

    console.log("Mobile==>", mobile);
    console.log("Pass==>", pass);

  const validate = () => {
    let FormValid = true;

    if (!mobile) {
      FormValid = false;
      mobileErr();
    }
    else if(!pass){
    FormValid = false;
    PassError()
  }
    return FormValid;
  }

  const SendAdmin = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (validate()) {
      dispatch(getUserData({
        contactNo:mobile,
        password:pass,
    }));
    } else {
      setLoader(false);
    }
}


  return (

<>
  <ToastContainer/>
    <div className='loginNew'>
      <Container fluid className='h-100'>
        <Row className='h-100'>
          <Col md={6} className='text-center text-white align-items-center justify-content-center left d-none d-md-flex'>
            
          </Col>
          <Col md={6} className='d-flex text-center align-items-center justify-content-center right px-2 px-md-5'>
          <Card className="card p-3">
            {/* <div>
              <img src={PCMC} className='img-fluid' width={100} alt="Pimpri Chinchwad Muncipal Coorpation"/>
            </div> */}
              <h2 className='mb-1 noselect'>Sakal ADVT</h2>
              {/* <Icon.BoxArrowInRight className="me-2"/> */}
              <h4 className='mb-1 noselect'>Log in</h4>

            <Form className="px-4">
              <FloatingLabel controlId="floatingInput" label="Mobile" className="mb-1" data-aos="fade-up" data-aos-delay="100">
                <Form.Control  placeholder="Mobile" className='username' name='contactNo' value={mobile} onChange={(e)=>{if (
                      !e.target.value.match(
                        /[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/
                      ) &&
                      e.target.value.length <= 10
                    ) {
                      setMobile(e.target.value);
                    }}}/>
              </FloatingLabel>

              <InputGroup  data-aos="fade-up" data-aos-delay="200" className="mb-4 noselect">
              <FloatingLabel controlId="floatingInput" label="Password" className='d-flex'>
              <Form.Control type={showPassword ? "text" : "password"} placeholder="Password" name='password' value={pass}  onChange={(e) => setPass(e.target.value)}  />
                  <InputGroup.Text className='user'>{ showPassword ? <Icon.EyeSlash size={20} style={{ cursor:'pointer' }} onClick={ () => setshowPassword(!showPassword) } /> : <Icon.Eye size={20} style={{ cursor:'pointer' }} onClick={ () => setshowPassword(!showPassword) } /> }</InputGroup.Text>
                  </FloatingLabel>
                </InputGroup>

                { loader ? <Spinner animation="border" variant="primary" /> :
            <Button className="btn-submit" type="submit" onClick={(e) => SendAdmin(e)} data-aos="fade-up" data-aos-delay="300" ><Icon.BoxArrowRight className='me-2'/>Login</Button>
                  }

              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>

    </>

  )
}

export default Login;