import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CommonLayout from './Layout/CommonLayout';
import OverallBiz1 from './Components/Admin/OverallBiz1';
import OverallBiz2 from './Components/Admin/OverallBiz2';
import OverallBiz3 from './Components/Admin/OverallBiz3';

import ScrollToTop from './Components/Common/ScrollToTop';
import CurrentYear from './Components/Admin/CurrentYear';
import Home from './Components/Common/Home';
import Login from './Feature/Login/Login';

function App() {
  return (
    <BrowserRouter>
    <ScrollToTop/>
    <Routes>
      <Route path='/' element={<Login/>}/>
      <Route path='/dashboard' element={<CommonLayout/>}>
        {/* <Route path='/dashboard' element={<Home/>}/> */}
        <Route path='/dashboard/current-year' element={<CurrentYear/>}/>
        <Route path='/dashboard/overall-business-performance-1' element={<OverallBiz1/>}/>
        <Route path='/dashboard/overall-business-performance-2' element={<OverallBiz2/>}/>
        <Route path='/dashboard/overall-business-performance-3' element={<OverallBiz3/>}/>
      </Route> 
    </Routes>
    </BrowserRouter>
  );
}

export default App;
