import React, { useEffect, useRef } from 'react';
import { Bar, getElementsAtEvent } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';

const SurplusDeficit = () => {

  let data = [
    {age:"10"},
    {age:"20"},
    {age:"50"},
    {age:"100"},
 ]

  let array = []

  data.map( (row) => {
    array.push(row?.age)
  } )

  const chartRef = useRef();
  const onClick = (event) => {
    console.log(getElementsAtEvent(chartRef.current, event));
  }

  

  return (
    <div>
       <Bar 
        ref={chartRef}
        onClick={onClick}
  
         style={{width:'100%', height:'100%'}}
         data={{
              labels:['Jan', 'Feb', 'Mar', 'Apr'],
              datasets:[
              {
              label:'One',
              data:array,
              borderWidth: 1,
              backgroundColor:[
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
              ],
              borderColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
            ],
             },
          {
            label:'Two',
            data:array,
                backgroundColor:[
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                  'rgba(255, 205, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                ],
                borderColor: [
                  'rgb(255, 99, 132)',
                  'rgb(255, 159, 64)',
                  'rgb(255, 205, 86)',
                  'rgb(75, 192, 192)',
                ],
              borderWidth: 1,
            },
        ]
         }}/>
    </div>
  )
}

export default SurplusDeficit;