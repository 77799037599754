import React, { useEffect, useState } from 'react';
import {Container, Row, Col, Card, Form, FloatingLabel, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';

import HorizBarChart from '../Common/HorizBarChart';
import SurplusDeficit from '../Common/SurplusDeficit';
import ActualTarget from '../Common/ActaulTarget';
import Doughnutchart from '../Common/Doughnutchart';

const OverallBiz2 = () => {

  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);

      /* Props variables for Overall Comparision Bar Chartjs */
      const [baseVsActualLabel, setBaseVsActualLabel] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getOverallBiz1();
  }, []);

  const getOverallBiz1 = async () => {
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/getAllYear2', {
      method: "GET",
      headers: { "Content-type": "application/json" }
    })
      .then((res) => res.json())
      .then((response) => {
        console.log("All charts data for Overall Business Report-1", response);
        setData(response.Data);
      })
      .catch((err) => {
        console.log("Error while Current Year", err);
      });
  }

      console.log("Data from SetData", data);

      useEffect(()=>{
        if(data !== '' && data !== undefined){
          getBaseVsActualVsTargetAllYears();
          // getSurplusDeficitAllYears();
          // getAchievementAllYears();
          // getGrowthAllYears();
        }
      },[data])

      const getBaseVsActualVsTargetAllYears=()=>{
        setBaseVsActualLabel(data.baseVsActualVsTargetAllYears.month);
        
      }


  return (
    <div className='overall'>
    <div className='title py-2'>
     <h5 className='mb-0'>Overall Business Report - 2</h5>
    </div>
   <Container fluid  data-aos="fade-up">
   <Row className='d-flex justify-content-end my-4'>
      <Col md={2} className='mb-3 md-mb-0'>
      <FloatingLabel controlId="floatingSelect" label="Month">
    <Form.Select aria-label="Floating label select example">
      <option>Select FY</option>
      <option value="1">2019-2020</option>
        <option value="2">2020-21</option>
        <option value="3">2021-2022</option>
    </Form.Select>
  </FloatingLabel>
      </Col>

  <Col md={2} className='mb-3 md-mb-0'>
    <FloatingLabel controlId="floatingSelect" label="Vertical">
      <Form.Select aria-label="Floating label select example">
        <option>Select Month</option>
        <option value="4">April</option>
        <option value="5">May</option>   
        <option value="6">June</option>   
        <option value="7">July</option>   
        <option value="8">August</option>   
        <option value="9">September</option>   
        <option value="10">October</option>   
        <option value="11">November</option>   
        <option value="12">December</option>   
        <option value="1">January</option>   
        <option value="2">February</option>   
        <option value="3">March</option>  
      </Form.Select>
    </FloatingLabel>
      </Col>

      <Col md={2}>
    <FloatingLabel controlId="floatingSelect" label="Vertical">
      <Form.Select aria-label="Floating label select example">
        <option>Select Vertical</option>
        <option value="1">Sakal</option>
        <option value="2">Digital</option>
        <option value="3">Something</option>
        <option value="4">Something</option>
      </Form.Select>
    </FloatingLabel>
      </Col>
    </Row>
 
  
    {/* ----------------------- Trendline Charts ---------------------------------*/}
    <Row>
      <Col md={6} className='mb-4 mb-md-0'>
        <Card>
          <p>Actual Vs Target Revenue - Trendline for all years</p>
          <ActualTarget/>
        </Card>
       
      </Col>
      <Col md={6} >
        <Card>
          <p>Actual Vs Base Revenue - Trendline for all years</p>
          <ActualTarget/>
        </Card>
      </Col>
    </Row>

    <Row className='mt-5'>
    <Col md={12} className='mb-4 mb-md-0'>
        <Card>
        <p>Surplus/Deficit Camparision for All years</p>
        <SurplusDeficit/>
        </Card>
      </Col>
    </Row>

    <Row className='mt-5'>
      <Col md={6} className='mb-4 mb-md-0'>
        <Card>
          <p>Growth comparision for all years</p>
          <SurplusDeficit/>
        </Card>
       
      </Col>
      <Col md={6} >
        <Card>
          <p>Vertical Wise Bifurcation of Total revenue for all years</p>
          <Doughnutchart/>
        </Card>
      </Col>
    </Row>


   </Container>
    </div>
  )
}

export default OverallBiz2;