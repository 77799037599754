import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Card, Spinner } from "react-bootstrap";
import CommonModal from "../Modal/CommonModal";

const LineChart = ({ months, Array, chartHeading }) => {
  
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (Array.length > 0) {
      setLoader(false);
    } else {
      setLoader(true);
    }
  }, [Array]);

  const data = {
    labels: months,
    // datasets: [
    //   {
    //     label: "Actual",
    //     data: actual,
    //     fill: true,
    //     backgroundColor: "rgba(75,192,192,0.2)",
    //     borderColor: "rgba(75,192,192,1)"
    //   },
    //   {
    //     label: "Target",
    //     data: target,
    //     fill: false,
    //     borderColor: "#742774"
    //   },
    // ]
    datasets: Array.map((row) => {
      console.log("row", row.data)
      return {
        label: row?.label,
        data: row?.data,
        fill: false,
        borderColor: row?.borderColor,
      };
    }),
  };


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

   /* ----- Charts Props for sending ClickEvent Data to Common Modal ------ */
   const [label, setLabel ] = useState();
   const [dataLabel, setDataLabel] = useState();
   const [value, setValue] = useState();

    
  
  return (
    <>
    <Card style={{width:'100%', height:'100%', padding:'15px'}}>
      <h6>{chartHeading}</h6>
      {loader ? (
        <center>
          <Spinner />
        </center>
      ) : (
        <Line data={data}
        options={{
          onClick: (e, element) => {
            console.log("Whole E", e);
            console.log("Element", element);

            let arr = [];
            e.chart.config._config.data.datasets.map((row) => {
              arr.push(row.data[ element[0].index]);
            });

            setLabel(e.chart.data.labels[element[0].index]);
            setDataLabel(e.chart.config._config.data.datasets[element[0].datasetIndex].label);
            setValue(arr[element[0].datasetIndex]);
            setShow(true)
          },
        }}
          />
      )}
    </Card>

    <CommonModal open={show} close={ () => setShow(false)} data={{label, dataLabel, value}}/>
    

    </>
  );
};

export default LineChart;
