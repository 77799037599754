import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';


const ActualTarget = () => {
    
const data = {
    labels: ["April","","","","Aug","","","Nov","","","","Mar"],
    datasets: [
      {
        label: "Actaul",
        data: [33, 53, 85, 41, 44, 65, 100, 68, 20, 10, 50, 80 ],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      },
      {
        label: "Target",
        data: [33, 25, 35, 51, 65, 76, 30, 40, 50, 82, 100, 90],
        fill: false,
        borderColor: "#742774"
      }
    ]
  };
  return (
    <div>
         <Line data={data} />

    </div>
  )
}

export default ActualTarget;