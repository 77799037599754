import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Card, Spinner } from "react-bootstrap";
import CommonModal from "../Modal/CommonModal";

const MultibarChart = ({ chartLabels, chartData, chartHeading }) => {
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (chartData !== undefined && chartData.length > 0) {
      setLoader(false);
      console.timeLog("ChartData to MultiChart", chartData);
    } else {
      setLoader(true);
    }
  }, [chartData]);

  
  const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const  handleShow = () => setShow(true);

  /* ----- Charts Props for sending ClickEvent Data to Common Modal ------ */
  const [label, setLabel ] = useState();
  const [dataLabel, setDataLabel] = useState();
  const [value, setValue] = useState();



  return (
    <>
      <Card style={{ height: "350px", padding: "30px 20px" }}>
        <h6>{chartHeading}</h6>
        {loader ? (
          <center>
            <Spinner />
          </center>
        ) : (
          <Bar
            data={{
              labels: chartLabels,
              fill: true,
              datasets: chartData.map((row) => {
                return {
                  label: row?.label,
                  data: row?.data,
                  fill: true,
                  backgroundColor: row?.backgroundColor,
                };
              }),
            }}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              onClick: (e, element) => {
                console.log("Whole E", e);
                console.log("Element", element);

                console.log("Label is : ", e.chart.data.labels[element[0].index]);
             

                console.log("Index", element[0].index);
                let index = element[0].index;
                // let dataindex = element[0].datasetIndex;

                let arr = [];
                e.chart.config._config.data.datasets.map((row) => {
                  arr.push(row.data[index]);
                });

                console.log("Onclick Array", arr);
                console.log("first", arr[element[0].datasetIndex]);

                console.log("Data set Array",e.chart.config._config.data.datasets[element[0].datasetIndex].label);


                setLabel(e.chart.data.labels[element[0].index]);
                setDataLabel(e.chart.config._config.data.datasets[element[0].datasetIndex].label);
                setValue(arr[element[0].datasetIndex]);
                setShow(true)

                // if(index!==undefined && index!==""){
                //   console.log("Data set Array", e.chart.config._config.data.datasets[index].label)
                // }
              },
            }}

            
          />
        )}
      </Card>

      <CommonModal open={show} close={ () => setShow(false)} data={{label, dataLabel, value}}/>
    </>
  );
};

export default MultibarChart;
