import React,{useEffect, useState} from 'react';
import {Bar} from 'react-chartjs-2';
// import { Chart as ChartJS } from 'chart.js/auto';
import { Card, Spinner } from 'react-bootstrap';


const HorizBarChart = ({chartLabels, chartLabel, chartBackgroundColor, chartData, chartHeading}) => {

  

  const [loader,setLoader] = useState(false)

  useEffect( () => {
    if(chartData !== undefined && chartData.length > 0){
      setLoader(false)

    }else{
      setLoader(true)
    } 
  },[chartData] )

 
  return (
    <Card style={{height:"350px", padding:'25px 20px'}}>
    <h6>{chartHeading}</h6>
  {
    loader ? 
    <center><Spinner /></center> :
 
       <Bar 
       data={{
        labels:chartLabels,
        fill:true,
        datasets:[
         {
          indexAxis: 'y',
           label:chartLabel,
           fill:true,
           data:chartData,
           backgroundColor:chartBackgroundColor
         }
       ]
    }}
    options={{ maintainAspectRatio: false}}/>
  }
        </Card>
  )
}

export default HorizBarChart;