import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Card, Spinner, Modal, Button } from "react-bootstrap";
import CommonModal from "../Modal/CommonModal";

const BarChart = ({ chartLabels, chartLabel, chartData, chartHeading }) => {
  const [loader, setLoader] = useState(false);

  const ColorArray = [];
  const BorderArray=[];

  useEffect(() => {
    if (chartData !== undefined && chartData.length > 0) {
      setLoader(false);
    } else {
      setLoader(true);
    }
  }, [chartData]);

  

  console.log("chartData At overll", chartData.length);

      for (var i = 0; i < chartData.length; i++) {
        if (chartData[i] > 0) {
          ColorArray.push("rgb(6, 169, 77, 0.4)");
          BorderArray.push("#059142");
        }
        if (chartData[i] < 0) {
          ColorArray.push("rgb(255, 0, 0, 0.3)");
          BorderArray.push("#FF0000");
        }
      }


  const [show, setShow] = useState(false);

   /* ----- Charts Props for sending ClickEvent Data to Common Modal ------ */
   const [label, setLabel ] = useState();
   const [dataLabel, setDataLabel] = useState();
   const [value, setValue] = useState();

  return (
    <>
      <Card style={{ height: "350px", padding: "25px 20px" }}>
        <h6>{chartHeading}</h6>
        {loader ? (
          <center>
            <Spinner />
          </center>
        ) : (
          <Bar
            data={{
              labels: chartLabels,
              fill: true,
              datasets: [
                {
                  label: chartLabel,
                  fill: true,
                  data: chartData,
                  backgroundColor: ColorArray,
                  borderColor:BorderArray,
                  borderWidth:2
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,
              // responsive: true,
              // onClick: (e, element) => {
              //   console.log("Element", e);
              //   console.log(
              //     "Label is : ",e.chart.data.labels[element[0].index]
              //   );
              // },
              
              responsive: true,
              onClick: (e, element) => {
                console.log("Whole E", e);
                console.log("Element", element);

                let arr = [];
                e.chart.config._config.data.datasets.map((row) => {
                  arr.push(row.data[ element[0].index]);
                });

                setLabel(e.chart.data.labels[element[0].index]);
                setDataLabel(e.chart.config._config.data.datasets[element[0].datasetIndex].label);
                setValue(arr[element[0].datasetIndex]);
                setShow(true)
              },
            }}
          />
        )}
      </Card>

      <CommonModal open={show} close={ () => setShow(false)} data={{label, dataLabel, value}}/>
    </>
  );
};

export default BarChart;
