import React from 'react';
import { Chart as ChartJS } from 'chart.js/auto';
import {Doughnut} from 'react-chartjs-2';




const Doughnutchart = () => {

  return (
    <div>
  
        <Doughnut
        data={{
             labels:['Jan', 'Feb', 'Mar', 'Apr'],
             datasets:[{
             data:[65,59, 82, 20],
             backgroundColor:[
               'red',
               'blue',
               'yellow',
               'black'
             ],
             hoverOffset: 10
             }]
        
}}/>
    </div>

  )
}

export default Doughnutchart;