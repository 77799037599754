import React,{useEffect, useState} from 'react';
import {Container, Row, Col, Card, Form, FloatingLabel, Button, Spinner, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';

import MultibarChart from '../Charts/MultibarChart';
import HorizBarChart from '../Charts/HorizBarChart';
import BarChart from '../Charts/BarChart';



const OverallBiz1 = () => {

  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);

  
  const navigate = useNavigate();

    /* Props variables for Overall Comparision Bar Chartjs */
      const [overallCompLabel, setOverallCompLabel] = useState([]);
      const [overallComparisionProp, setOverallComparisionProp] = useState([]);

    /* Props variables for Surplus Deficit Bar Chartjs */
    const [surplusDeficitLabel, setSurplusDeficitLabel] = useState([]);
    const [surplusDeficitData, setSurplusDeficitData] = useState([]);
    
    /* Props variables for Surplus Deficit Bar Chartjs */
      const [achievementLabel, setAchievementLabel] = useState([]);
      const [achievementData, setAchievementData] = useState([]);

    /* Props variables for Surplus Deficit Bar Chartjs */
       const [growthLabel, setGrowthLabel] = useState([]);
       const [growthData, setGrowthData] = useState([]);


  useEffect(() => {
    getOverallBiz1();
  }, []);

  const getOverallBiz1 = async () => {
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/bpr/getAllYear', {
      method: "GET",
      headers: { "Content-type": "application/json" }
    })
      .then((res) => res.json())
      .then((response) => {
        console.log("All charts data for Overall Business Report-1", response);
        setData(response.Data);
      })
      .catch((err) => {
        console.log("Error while Current Year", err);
      });
  }

      console.log("Data from SetData", data);


useEffect(()=>{
  if(data !== '' && data !== undefined){
    getOverallComparisonOfVerticalAllYears();
    getSurplusDeficitAllYears();
    getAchievementAllYears();
    getGrowthAllYears();
  }
},[data])

 // -------- Setting Data for Overall Comparision of all verticals MultiBar Chart ----------

  const getOverallComparisonOfVerticalAllYears=()=>{
     
    setOverallCompLabel(data.overallComparisonOfVerticalAllYears.vertical);

    let OverallComparisionArray=[];

    OverallComparisionArray.push({
      label:"Actual",
      fill:true,
      data:[data.overallComparisonOfVerticalAllYears.actual[0], data.overallComparisonOfVerticalAllYears.actual[1], 
      data.overallComparisonOfVerticalAllYears.actual[2], data.overallComparisonOfVerticalAllYears.actual[3]],
      backgroundColor:"rgba(17,141,255,255)"
    });

    OverallComparisionArray.push( {
      label:"Base",
      fill:true,
      data:[data.overallComparisonOfVerticalAllYears.base[0],
      data.overallComparisonOfVerticalAllYears.base[1], 
      data.overallComparisonOfVerticalAllYears.base[2],  data.overallComparisonOfVerticalAllYears.base[3]],
      backgroundColor:"rgba(18,35,158,255)"
    });

    OverallComparisionArray.push( {
      label:"Target",
      fill:true,
      data:[data.overallComparisonOfVerticalAllYears.target[0],
      data.overallComparisonOfVerticalAllYears.target[1], 
      data.overallComparisonOfVerticalAllYears.target[2], data.overallComparisonOfVerticalAllYears.target[3]],
      backgroundColor:"#e66c37"
    });

    setOverallComparisionProp(OverallComparisionArray);
}

const getSurplusDeficitAllYears=()=>{
  setSurplusDeficitLabel(data.surplusDeficitAllYears.vertical);
  setSurplusDeficitData(data.surplusDeficitAllYears.surplus_deficit);
}

  const getAchievementAllYears=()=>{
    setAchievementLabel(data.achievementAllYears.vertical);
    setAchievementData(data.achievementAllYears.achievement);
   }

   const getGrowthAllYears=()=>{
    setGrowthLabel(data.growthAllYears.vertical);
    setGrowthData(data.growthAllYears.growth);
   }



  return (
    <div className='overall'>
      <div className='title py-2'>
       <h5 className='mb-0'>Overall Business Report - 1</h5>
      </div>
     <Container fluid  data-aos="fade-up">
     <Row className='d-flex justify-content-end my-4'>
        <Col md={2} className='mb-3 md-mb-0'>
        <FloatingLabel controlId="floatingSelect" label="Year">
          <Form.Select aria-label="Floating label select example">
            <option>Select FY</option>
            <option value="1">2019-2020</option>
              <option value="2">2020-21</option>
              <option value="3">2021-2022</option>
          </Form.Select>
      </FloatingLabel>
        </Col>

    <Col md={2} className='mb-3 md-mb-0'>
      <FloatingLabel controlId="floatingSelect" label="Month">
        <Form.Select aria-label="Floating label select example">
          <option>Select Month</option>
          <option value="1">Jan</option>
          <option value="2">Feb</option>
          <option value="3">Dec</option>
        </Form.Select>
      </FloatingLabel>
        </Col>

        <Col md={2}>
      <FloatingLabel controlId="floatingSelect" label="Vertical">
        <Form.Select aria-label="Floating label select example">
          <option>Select Vertical</option>
          <option value="1">Sakal</option>
          <option value="2">Digital</option>
          <option value="3">Something</option>
          <option value="4">Something</option>
        </Form.Select>
      </FloatingLabel>
        </Col>
      </Row>
   
      <Row className='gy-4'>
        <Col md={4}>
          <Card className='py-3 text-center' data-aos="fade-up" data-aos-delay="100">
            <div className='d-flex justify-content-center align-items-center mb-2'>
              <Icon.CurrencyRupee className='me-1' size={25}/>
              <h5 className="mb-0">{data!==undefined ?  data.surplusAchieveGrowthAllYears.base_sum : <loader><Spinner/></loader>} Cr</h5>
            </div>
            <p className='mb-0'>Base Revenue</p>
          </Card>
        </Col>

        <Col md={4}>
          <Card className='py-3 text-center' data-aos="fade-up" data-aos-delay="200">
            <div className='d-flex justify-content-center align-items-center mb-2'>
              <Icon.CurrencyRupee className='me-1' size={30}/>
              <h5 className="mb-0">{data!==undefined ?  data.surplusAchieveGrowthAllYears.actual_sum : <loader><Spinner/></loader>} Cr</h5>
            </div>
            <p className='mb-0'>Actual Revenue</p>
          </Card>
        </Col>

        <Col md={4}>
          <Card className='py-3 text-center'  data-aos="fade-up" data-aos-delay="300">
            <div className='d-flex justify-content-center align-items-center mb-2'>
              <Icon.CurrencyRupee className='me-1' size={30}/>
              <h5 className="mb-0">{data!==undefined ?  data.surplusAchieveGrowthAllYears.target_sum : <loader><Spinner/></loader>} Cr</h5>
            </div>
            <p className='mb-0'>Target Revenue</p>
          </Card>
        </Col>

        <Col md={4}>
          <Card className='py-3 text-center' data-aos="fade-up" data-aos-delay="400">
            <div className='d-flex justify-content-center align-items-center mb-2'>
              <Icon.CurrencyRupee className='me-1' size={30}/>
              <h5 className="mb-0">{data!==undefined ?  data.surplusAchieveGrowthAllYears.surplus_deficit : <loader><Spinner/></loader>} Cr</h5>
            </div>
            <p className='mb-0'>Surplus/Deficit</p>
          </Card>
        </Col>

        <Col md={4}>
          <Card className='py-3 text-center' data-aos="fade-up" data-aos-delay="500">
            <div className='d-flex justify-content-center align-items-center mb-2'>
         <h5 className="mb-0">{data!==undefined ?  data.surplusAchieveGrowthAllYears.achievement : <loader><Spinner/></loader>} %</h5>
            </div>
            <p className='mb-0'>% Achievements</p>
          </Card>
        </Col>

        <Col md={4}>
          <Card className='py-3 text-center' data-aos="fade-up" data-aos-delay="600">
            <div className='d-flex justify-content-center align-items-center mb-2'>
            <h5 className="mb-0">{data!==undefined ?  data.surplusAchieveGrowthAllYears.growth : <loader><Spinner/></loader>} %</h5>
            </div>
            <p className='mb-0'>% Growth</p>
          </Card>
        </Col>
      </Row>

    {/* ----------------------- Charts ---------------------------------*/}
      <Row className="mt-5">
        <Col md={6} className='mb-4 mb-md-0'>
          <MultibarChart chartLabels={overallCompLabel} chartData={overallComparisionProp}
              chartHeading="Overall Comparision of Business verticals of all years"/>
        </Col>
        <Col md={6}>
        <HorizBarChart chartLabels={surplusDeficitLabel} chartLabel="Verticals" chartBackgroundColor="#a1343c" 
            chartData={surplusDeficitData} chartHeading="Surplus/Deficit Comparision of Business Verticals for All years"/>
        </Col>
      </Row>

      <Row className='mt-5'>
      <Col md={6} className='mb-4 mb-md-0'>
      <BarChart chartLabels={achievementLabel} chartLabel="Verticals" postiveColor="#00FF00" negativeColor="#FF0000" 
            chartData={achievementData} chartHeading="Achievement Camparision of Different Verticals for All years"/>
        </Col>

        <Col md={6}>
         <BarChart chartLabels={growthLabel} chartLabel="Verticals" postiveColor="#00FF00" negativeColor="#FF0000"
            chartData={growthData} chartHeading="Growth Camparision Different Verticals for All years"/>
        </Col>
      </Row>

      <div className='d-flex flex-column flex-md-row justify-content-between align-items-center my-4'>
          <Button className='btn-navigate-left' onClick={()=>navigate('/overall-business-performance-2')}><Icon.ArrowLeft className='me-2'/>Go to Trend Charts</Button>
          <Button className='btn-navigate-right' onClick={()=>navigate('/overall-business-performance-3')}>Go to Trend Charts for year wise comparision<Icon.ArrowRight className='ms-2'/></Button>
      </div>

     </Container>
  </div>
  )
}

export default OverallBiz1;