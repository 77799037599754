import React from 'react';
import { Outlet } from 'react-router-dom';
import Menubar from '../Components/Common/Menubar';
import Sidebar from '../Components/Common/Sidebar';
import { Container, Row, Col } from 'react-bootstrap';

const CommonLayout = () => {
  return (
    <div>
    <Menubar/>
    <Container fluid className=''>
    <Row>
    <div className='sidenav-lg-parent'>
    <Col className='ps-0 d-none d-lg-block'>
      <Sidebar/>
    </Col>
      <Col>
        <div className='outletDiv px-3'>
        <Outlet/>
      </div>
      </Col>
      </div>
    </Row>
  </Container>
  </div>
  )
}

export default CommonLayout;