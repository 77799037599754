import React, { useState } from "react";
import { Accordion, Button, Nav, Spinner } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogout } from "../../Feature/Login/LoginSlice";


const Sidebar = () => {

    const navigate =  useNavigate();
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);

    const handleLogout=()=>{
      setLoader(true);
      dispatch(setLogout(null));
      //  dispatch(setActiveKey(0));
      //  dispatch(setLabelDate(''));
      //  dispatch( setLabelMonth(''));
      setLoader(false);
      navigate('/');
    }

  return (
    <>
    <div className="sidenav-lg d-flex justify-content-between flex-column">
    <Accordion defaultActiveKey="0" flush>
        <Accordion.Item eventKey="0">
        <Accordion.Header><Icon.HouseDoor className='me-2'/>Business Performance</Accordion.Header>
        <Accordion.Body>
          <Nav variant="pills" defaultActiveKey="/dashboard/current-year" className="d-flex flex-column">
            <Nav.Item>
                <Nav.Link onClick={ () => {navigate("/dashboard/current-year")}}  eventKey="/current-year">
                  <Icon.Activity className="me-3"/>FY 2021-2022</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link onClick={ () => {navigate("/dashboard/overall-business-performance-1")}} eventKey="/dashboard/overall-business-performance-1">
                   <div><Icon.Calendar2Week className="me-3"/></div><div>Business Report 1</div></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link onClick={ () => {navigate("/dashboard/overall-business-performance-2")}} eventKey="/dashboard/overall-business-performance-2">
                <Icon.Calendar2Week className="me-3"/>Business Report 2</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link onClick={ () => {navigate("/dashboard/overall-business-performance-3")}} eventKey="/dashboard/overall-business-performance-3">
                <Icon.Calendar2Week className="me-3"/>Business Report 3</Nav.Link>
            </Nav.Item>
          </Nav>
        </Accordion.Body>
      </Accordion.Item>

      {/* <Accordion.Item eventKey="1">
           <Accordion.Header><Icon.ArrowDownCircle className='me-2'/>FY 2021-222 Sakal</Accordion.Header>
           <Accordion.Body>
           <Button className="w-100 mb-2 btn-accordionMenu" onClick={ () => {navigate("/current-year")}}>
            <Icon.Activity className="me-2"/>FY 2021-2022</Button>
          <Button className="w-100 mb-2 btn-accordionMenu" onClick={ () => {navigate("/overall-business-performance-1")}}>
            <Icon.Activity className="me-2"/>Overall Business Report-1</Button>
          <Button className="w-100 mb-2 btn-accordionMenu" onClick={ () => {navigate("/overall-business-performance-2")}}>
            <Icon.Activity className="me-2"/>Overall Business Report-2</Button>
            <Button className="w-100 mb-2 btn-accordionMenu" onClick={ () => {navigate("/overall-business-performance-3")}}>
            <Icon.Activity className="me-2"/>Overall Business Report-3</Button>
           </Accordion.Body>
         </Accordion.Item> */}
{/*  
    <Accordion.Item eventKey="2">
           <Accordion.Header><Icon.ArrowDownCircle className='me-2'/>FY 2021-222 Digital</Accordion.Header>
           <Accordion.Body>
           <Button className="w-100 mb-2 btn-accordionMenu" onClick={ () => {navigate("/current-year")}}>
            <Icon.Activity className="me-2"/>FY 2021-2022</Button>
          <Button className="w-100 mb-2 btn-accordionMenu" onClick={ () => {navigate("/overall-business-performance-1")}}>
            <Icon.Activity className="me-2"/>Overall Business Report-1</Button>
          <Button className="w-100 mb-2 btn-accordionMenu" onClick={ () => {navigate("/overall-business-performance-2")}}>
            <Icon.Activity className="me-2"/>Overall Business Report-2</Button>
            <Button className="w-100 mb-2 btn-accordionMenu" onClick={ () => {navigate("/overall-business-performance-3")}}>
            <Icon.Activity className="me-2"/>Overall Business Report-3</Button>
           </Accordion.Body>
         </Accordion.Item> */}

         {/* <Accordion.Item eventKey="3">
           <Accordion.Header><Icon.ArrowDownCircle className='me-2'/>FY 2021-222 Agrowon</Accordion.Header>
           <Accordion.Body>
           <Button className="w-100 mb-2 btn-accordionMenu" onClick={ () => {navigate("/current-year")}}>
            <Icon.Activity className="me-2"/>FY 2021-2022</Button>
          <Button className="w-100 mb-2 btn-accordionMenu" onClick={ () => {navigate("/overall-business-performance-1")}}>
            <Icon.Activity className="me-2"/>Overall Business Report-1</Button>
          <Button className="w-100 mb-2 btn-accordionMenu" onClick={ () => {navigate("/overall-business-performance-2")}}>
            <Icon.Activity className="me-2"/>Overall Business Report-2</Button>
            <Button className="w-100 mb-2 btn-accordionMenu" onClick={ () => {navigate("/overall-business-performance-3")}}>
            <Icon.Activity className="me-2"/>Overall Business Report-3</Button>
           </Accordion.Body>
         </Accordion.Item> */}
    </Accordion>

    <div className="pb-3 ps-2">
  <Button className='btn-logout w-100' onClick={handleLogout}>{ loader ? <Spinner animation="border" variant="light" className='spinner'/> :<><Icon.BoxArrowLeft size={20} className='me-2'/>Logout</>}</Button>
</div>
      </div>
    </>
  );
};

export default Sidebar;
