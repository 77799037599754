import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from "react-router-dom";

import * as Icon from 'react-bootstrap-icons';
import { Button, Spinner, Accordion, Nav, Popover, OverlayTrigger } from 'react-bootstrap';
import { useSelector } from 'react-redux';


const Menubar = () => {

  const userState = useSelector(state => state.loginData.userData);


  function setnav(){
    const mobilenav = document.querySelector(".mobile-nav");
    const toggle = document.querySelector(".mobile-nav-toggle");
  
    const visibility = mobilenav.getAttribute('data-visible' );
    if(visibility ==="false"){
        mobilenav.setAttribute("data-visible" , true);
        toggle.setAttribute("aria-expanded", true);
        document.querySelector("body").style.overflow='hidden';
    }
    else if(visibility === "true"){
        mobilenav.setAttribute("data-visible" , false);
        toggle.setAttribute("aria-expanded", false);
        document.querySelector("body").style.overflow='auto';
        
    }
  }


  const [loader,setLoader] = useState(false);
  const navigate = useNavigate();

 const handleLogout=()=>{
  setLoader(true);
  localStorage.clear();
  navigate('/');
  setLoader(false);
 }


 const entering = (e) => {
  e.children[0].style.borderTopColor = 'white';
  e.children[1].style.backgroundColor = 'white';
  e.children[0].style.borderRadius = '12px';
  e.children[1].style.borderRadius = '12px';
};

const Popup=(
<Popover>
  <Popover.Body>
    <div className='PopupData'>
      <div>
         <Icon.Person className='me-1' size={18}/><p><strong>Name - </strong>{userState.name}</p>
       </div>
       <div>
         <Icon.Telephone className='me-2' size={14}/><p><strong>Mobile - </strong>{userState.contactNo}</p>
       </div>
       <div>
         <Icon.Bullseye className='me-2' size={15}/><p><strong>Division - </strong>{userState.division}</p>
       </div>
       <div>
         <Icon.Buildings className='me-2' size={15}/><p><strong>Vertical - </strong>{userState.vertical}</p>
       </div>
       {/* <div>
         {userState.isActive ? <Icon.ToggleOn className='me-2' size={15}/> :<Icon.ToggleOff className='me-2' size={15}/>}
         <p><strong>Status - </strong>{userState.isActive ? "Active" : "InActive"}</p>
       </div> */}
      </div>
 </Popover.Body>
</Popover>
)

  return (

    <nav>
       <Link to="/"><h5 className='mb-0'>ADVT Sakal</h5></Link>
      

    <div className="mobile-nav-toggle" aria-controls='selectNav' aria-expanded="false"  onClick={setnav}>
        <div className='menu-btn__burger'></div>
    </div>
 
    <ul className="lg-nav">
    <OverlayTrigger key='bottom' placement='bottom' overlay={Popup}
          onEntering={entering}>
            <div className='d-flex' style={{border:'none'}}>
         <Icon.PersonFill className='me-2' size={25}/> <p className='mb-0'>{  userState.role==="superAdmin" ? "Super Admin" : "" } </p> 
         </div>
        </OverlayTrigger>
   
    {/* <Button className='btn btn-logout ms-3' onClick={handleLogout}>{ loader ? <Spinner animation="border" variant="light" className='spinner'/> :<><Icon.BoxArrowLeft className='me-2'/>Logout</>}</Button> */}
    </ul>
    
    <ul id="selectNav" className="mobile-nav" data-visible="false">
    <div className="sidenav-lg">
    <Accordion defaultActiveKey="0" flush className="">
      <Accordion.Item eventKey="0">
        <Accordion.Header><Icon.HouseDoor className='me-2'/>Home</Accordion.Header>
        <Accordion.Body>
          <Nav variant="pills" defaultActiveKey="/current-year" className="d-flex flex-column">
            <Nav.Item>
                <Nav.Link onClick={ () => {navigate("/current-year"); setnav()}}  eventKey="/current-year">
                  <Icon.Activity className="me-3"/>FY 2021-2022</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link onClick={ () => {navigate("/overall-business-performance-1"); setnav()}} eventKey="/overall-business-performance-1">
                   <Icon.Calendar2Week className="me-3"/>Business Report-1</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link onClick={ () => {navigate("/overall-business-performance-2"); setnav()}} eventKey="/overall-business-performance-2">
                <Icon.Calendar2Week className="me-3"/>Business Report-2</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link onClick={ () => {navigate("/overall-business-performance-3"); setnav()}} eventKey="/overall-business-performance-3">
                <Icon.Calendar2Week className="me-3"/>Business Report-3</Nav.Link>
            </Nav.Item>
          </Nav>
        </Accordion.Body>
      </Accordion.Item>
      
      <Accordion.Item eventKey="1">
           <Accordion.Header><Icon.ArrowDownCircle className='me-2'/>Other Roles</Accordion.Header>
           {/* <Accordion.Body>
           <Link to="/admin/enter-mobile"><Button className="w-100 mb-2 btn-accordionMenu" onClick={setnav}>
            <Icon.PersonAdd className='me-2'/>Register User</Button></Link>
            <Link to="/admin/add-member-attendance"><Button className="w-100 mb-2 btn-accordionMenu" onClick={setnav}>
            <Icon.PersonAdd className='me-2'/>Register User without OTP</Button></Link>
           </Accordion.Body> */}
         </Accordion.Item>
    
    </Accordion>
      </div>

      <Button className='btn btn-logout m-5' onClick={handleLogout}>{ loader ? <Spinner animation="border" variant="light" className='spinner'/> :<><Icon.BoxArrowLeft className='me-2'/>Logout</>}</Button>
    </ul>
    
</nav>
  )
}

export default Menubar;