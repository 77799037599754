import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Container,
  Row,
  Col,
  Form,
  FloatingLabel,
  Button,
  Spinner,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import SurplusDeficit from "../Common/SurplusDeficit";
import ActualTarget from "../Common/ActaulTarget";
import ActualBase from "../Common/ActualBase";
import ActualAndTaregt from "../Common/ActualAndTaregt";

import BarChart from "../Charts/BarChart";
import LineChart from "../Charts/LineChart";
import MultibarChart from "../Charts/MultibarChart";

const CurrentYear = () => {
  /* Props variables for Surplus Deficit Bar Chartjs */
  const [barChartLabels, setBarChartLabels] = useState([]);
  const [barChartdata, setBarChartdata] = useState([]);

  /* Props variables for Actual Vs Target Line Chartjs */
  const [LineChartlabel, setLineChartLabel] = useState([]);
  const [actualVsTargetProp, setActualVsTargetProp] = useState([]);
  const [actualVsBaseProp, setActualVsBaseProp] = useState([]);

  /* Props variables for Overall Comparision Bar Chartjs */
  const [overallCompLabel, setOverallCompLabel] = useState([]);
  const [overallComparisionProp, setOverallComparisionProp] = useState([]);
 
  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getCurrentYear();
  }, []);


  useEffect(() => {
    if (loader === true) {
      setLoader(false);
    }
  }, [barChartdata]);

//  useEffect(()=>{
//   console.log("Overall Comparision Props", overallComparisionProp)
//  },[overallComparisionProp])

  const getCurrentYear = async () => {
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + `/bpr/getCurrentYear`, {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        fromDate: "2021-04-01",
        toDate: "2022-03-31",
        month:Number(month),
        vertical:vertical
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        console.log("All charts data for current year", response);
        setData(response.Data);

        // ---- Setting Data for Surplus/Deficit Bar Chart ---------
        setBarChartLabels(response.Data.surplusDeficit.vertical);
        setBarChartdata(response.Data.surplusDeficit.surplus_deficit);

        // ---- Setting Data for Actual Vs Target Line Chart ---------
        setLineChartLabel(response.Data.baseVsActualVsTarget.month);
        let ActualVsTarget = [];
          ActualVsTarget.push({
            label: "Actual",
            data: response.Data.baseVsActualVsTarget.actual,
            borderColor: "#118DFF",
          });
          ActualVsTarget.push({
            label:'Target',
            data:response.Data.baseVsActualVsTarget.target,
            borderColor: "#12239E"
          });
          setActualVsTargetProp(ActualVsTarget);

      // ------- Setting Data for Actual Vs Target Line Chart ---------
        setLineChartLabel(response.Data.baseVsActualVsTarget.month);
        let ActualVsBase = [];
        ActualVsBase.push({
            label: "Actual",
            data: response.Data.baseVsActualVsTarget.actual,
            borderColor: "#118DFF"
          });
          ActualVsBase.push({
            label:'Base',
            data:response.Data.baseVsActualVsTarget.base,
            borderColor:"#E66C37"
          });
          setActualVsBaseProp(ActualVsBase);
     

      // ---- Setting Data for Surplus/Deficit Comparision Bar Chart ---------
        setOverallCompLabel(response.Data.overallComparisonOfVertical.vertical);

        let OverallComparisionArray=[];

        OverallComparisionArray.push({
          label:"Actual",
          fill:true,
          data:[response.Data.overallComparisonOfVertical.actual[0], response.Data.overallComparisonOfVertical.actual[1], 
          response.Data.overallComparisonOfVertical.actual[2], response.Data.overallComparisonOfVertical.actual[3]],
          backgroundColor:"rgba(17,141,255,255)"
        });
        OverallComparisionArray.push( {
          label:"Base",
          fill:true,
          data:[response.Data.overallComparisonOfVertical.base[0],
          response.Data.overallComparisonOfVertical.base[1], 
          response.Data.overallComparisonOfVertical.base[2],  response.Data.overallComparisonOfVertical.base[3]],
          backgroundColor:"rgba(18,35,158,255)"
        });
        OverallComparisionArray.push( {
          label:"Target",
          fill:true,
          data:[response.Data.overallComparisonOfVertical.target[0],
          response.Data.overallComparisonOfVertical.target[1], 
          response.Data.overallComparisonOfVertical.target[2], response.Data.overallComparisonOfVertical.target[3]],
          backgroundColor:"#e66c37"
        });

        // {OverallComparisionArray!==undefined && OverallComparisionArray.length>0 ?
        //   console.log("OverallComparisionArray", OverallComparisionArray):"" }
          console.log("OverallComparisionArray", OverallComparisionArray)
          setOverallComparisionProp(OverallComparisionArray);
        
          console.log("overallComparisionProp", overallComparisionProp)
       



        // let OverallComparision = {
        //   labels:overallCompLabel,
        //   datasets:[
        //     {
        //       label: "Sakal",
        //       backgroundColor: "pink",
        //       borderColor: "red",
        //       borderWidth: 1,
        //       data: [response.Data.overallComparisonOfVertical.actual[0],response.Data.overallComparisonOfVertical.base[0],
        //       response.Data.overallComparisonOfVertical.target[0]]
        //     },
        //     {
        //       label: "Event",
        //       backgroundColor: "yellow",
        //       borderColor: "red",
        //       borderWidth: 1,
        //       data: [response.Data.overallComparisonOfVertical.actual[1],response.Data.overallComparisonOfVertical.base[1],
        //       response.Data.overallComparisonOfVertical.target[1]]
        //     },
        //     {
        //       label: "Agrowon",
        //       backgroundColor: "red",
        //       borderColor: "red",
        //       borderWidth: 1,
        //       data: [response.Data.overallComparisonOfVertical.actual[2],response.Data.overallComparisonOfVertical.base[2],
        //       response.Data.overallComparisonOfVertical.target[2]]
        //     },
        //     {
        //       label: "Digital",
        //       backgroundColor: "black",
        //       borderColor: "red",
        //       borderWidth: 1,
        //       data: [response.Data.overallComparisonOfVertical.actual[3],response.Data.overallComparisonOfVertical.base[3],
        //       response.Data.overallComparisonOfVertical.target[3]]
        //     }
        //   ]
        // };
        // setOverallComparisionProp(OverallComparision);
     
        // OverallComparision.push({
        //   label:'Sakal',
        //   dataArray:[response.Data.overallComparisonOfVertical.actual[0], response.Data.overallComparisonOfVertical.base[1],
        //     response.Data.overallComparisonOfVertical.target[2]],
        //   backgroundColor:'#118DFF'
        // });
        // OverallComparision.push({
        //   label:'Event',
        //   dataArray:[response.Data.overallComparisonOfVertical.actual[1], response.Data.overallComparisonOfVertical.base[1],
        //   response.Data.overallComparisonOfVertical.target[1]],
        //   backgroundColor:'#12239E'
        // });
        // OverallComparision.push({
        //   label:'Agrowon',
        //   dataArray:[response.Data.overallComparisonOfVertical.actual[2], response.Data.overallComparisonOfVertical.base[2],
        //   response.Data.overallComparisonOfVertical.target[2]],
        //   backgroundColor:'#E66C37'
        // });
        // OverallComparision.push({
        //   label:'Digital',
        //   dataArray:[response.Data.overallComparisonOfVertical.actual[3], response.Data.overallComparisonOfVertical.base[3],
        //   response.Data.overallComparisonOfVertical.target[3]],
        //   backgroundColor:'#000000'
        // });
     
      })
      .catch((err) => {
        console.log("Error while Current Year", err);
      });
  }


console.log("data", data);

const [month, setMonth] = useState(0);
const [vertical, setVertical] = useState("");

  return (

    <div className="overall">
      <div className="title py-2">
        <h5 className="mb-0">
          FY 2021-2022 Overall Business Perfomance Report Summary
        </h5>
      </div>
      <Container fluid>
        <Row className="d-flex justify-content-end mt-3">
          <Col md={2} className='mb-3 md-mb-0'>
            <FloatingLabel label="Month">
              <Form.Select onChange={(e)=>setMonth(e.target.value)}>
                <option value="" selected disabled>Select Month</option>
                <option value="4">April</option>
                <option value="5">May</option>   
                <option value="6">June</option>   
                <option value="7">July</option>   
                <option value="8">August</option>   
                <option value="9">September</option>   
                <option value="10">October</option>   
                <option value="11">November</option>   
                <option value="12">December</option>   
                <option value="1">January</option>   
                <option value="2">February</option>   
                <option value="3">March</option>  
              </Form.Select>
            </FloatingLabel>
          </Col>

          <Col md={2} className='mb-3 md-mb-0'>
            <FloatingLabel label="Vertical">
              <Form.Select onChange={(e)=>setVertical(e.target.value)}>
                <option>Select Vertical</option>
                <option value="Sakal">Sakal</option>
                <option value="Event">Event</option>
                <option value="Agrowon">Agrowon</option>
                <option value="Digital">Digital</option>
              </Form.Select>
            </FloatingLabel>
          </Col>

          <Col md={1} className='mb-3 md-mb-0 d-flex justify-content-center align-items-center'>
              <Button className="btn btn-submit" onClick={getCurrentYear} disabled={month!==0 && vertical ? false : true}>Search</Button>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={3} className="mb-4 mb-md-0">
            <Card
              className="py-3 text-center" data-aos="fade-up" data-aos-delay="100">
              <div className="d-flex justify-content-center align-items-center mb-2">
                <Icon.CurrencyRupee className="me-1" size={25} />
                <h5 className="mb-0">{data!==undefined ?  data.surplusAchieveGrowth.surplus_deficit : <loader><Spinner/></loader>}</h5>
              </div>
              <p>Surplus/Deficit in Cr</p>
            </Card>
          </Col>
          <Col md={3} className="mb-4 mb-md-0">
            <Card
              className="py-3 text-center" data-aos="fade-up" data-aos-delay="200">
              <div className="d-flex justify-content-center align-items-center mb-2">
                <h5 className="mb-0">{data!==undefined ? data.surplusAchieveGrowth.achievement : <loader><Spinner/></loader>} %</h5>
              </div>
              <p>Achievements</p>
            </Card>
          </Col>
          <Col md={3}>
            <Card
              className="py-3 text-center" data-aos="fade-up" data-aos-delay="300">
              <div className="d-flex justify-content-center align-items-center mb-2">
                <h5 className="mb-0">{data!==undefined ? data.surplusAchieveGrowth.growth : <loader><Spinner/></loader>} %</h5>
              </div>
              <p>Growth</p>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4 justify-content-center">
          <Col md={6} className="mb-4 mb-md-0">
            <Card className="h-100 pt-3 ">
              <h6>Actual & Target Revenue</h6>
                <div className="h-100 actualAndTarget d-flex justify-content-center align-items-center py-5 md-py-0">
                  { data!==undefined ? <div> 
                  <h1>{data.actualAndTarget.actual} Cr</h1>
                  <div className="d-flex justify-content-center align-items-center">
                  <h4>Goal:<Icon.CurrencyRupee className="me-1"/>{data.actualAndTarget.target}</h4>
                  <p className="ms-2">( { (((data.actualAndTarget.actual / data.actualAndTarget.target)*100)-100).toFixed(2)}% )</p>
                  </div>
             </div>
                  : <loader><Spinner variant="light"/></loader>}
                </div>
            </Card>
          </Col>

          <Col md={6}>
            <BarChart chartLabels={barChartLabels} chartLabel="Verticals" 
            chartData={barChartdata} chartHeading="Surplus/Deficit Comparision of Business verticals"/>
          </Col>
        </Row>

        <Row className="mt-4 justify-content-center">
          <Col md={6} className="mb-4 mb-md-0">
            <LineChart months={LineChartlabel} Array={actualVsTargetProp} chartHeading="Actual Vs Taregt Revenue - Trendline" />
          </Col>

          <Col md={6}>
            <LineChart months={LineChartlabel} Array={actualVsBaseProp} chartHeading="Actual Vs Base Revenue - Trendline"/>
          </Col>
        </Row>

        <Row className="mt-4 justify-content-center">
          <Col md={12}>
            <MultibarChart chartLabels={overallCompLabel} chartData={overallComparisionProp}
              chartHeading="Surplus/Deficit Comparision of Business verticals"/>
          </Col>

          <div className="d-flex justify-content-end my-4">
            <Button className="btn-navigate-right" onClick={() => navigate("/overall-business-performance-1")}>
                  Go to Business Report
              <Icon.ArrowRight className="ms-2" />
            </Button>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default CurrentYear;
