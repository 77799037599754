import React from 'react';
import { Container, Row, Col, Card, Form, FloatingLabel } from 'react-bootstrap';
import SingleLinechart from '../Common/SingleLinechart';

const OverallBiz3 = () => {
  return (
    <div className='overall'>
    <div className='title py-2'>
     <h5 className='mb-0'>Overall Business Report - 3</h5>
    </div>
   <Container fluid  data-aos="fade-up">
   <Row className='d-flex justify-content-end my-4'>
      <Col md={2}>
      <FloatingLabel controlId="floatingSelect" label="Month">
    <Form.Select aria-label="Floating label select example">
      <option>Select FY</option>
      <option value="1">2019-2020</option>
        <option value="2">2020-21</option>
        <option value="3">2021-2022</option>
    </Form.Select>
  </FloatingLabel>
      </Col>

  <Col md={2}>
    <FloatingLabel controlId="floatingSelect" label="Vertical">
      <Form.Select aria-label="Floating label select example">
        <option>Select Month</option>
        <option value="1">Jan</option>
        <option value="2">Feb</option>
        <option value="3">Dec</option>
      </Form.Select>
    </FloatingLabel>
      </Col>

      <Col md={2}>
    <FloatingLabel controlId="floatingSelect" label="Vertical">
      <Form.Select aria-label="Floating label select example">
        <option>Select Vertical</option>
        <option value="1">Sakal</option>
        <option value="2">Digital</option>
        <option value="3">Something</option>
        <option value="4">Something</option>
      </Form.Select>
    </FloatingLabel>
      </Col>
    </Row>
 
  
    {/* ----------------------- Trendline Charts ---------------------------------*/}
    <Row>
      <Col md={6} className='mb-4 mb-md-0'>
        <Card>
          <p>Year Wise Comparision of Actual Revenue</p>
          <SingleLinechart/>
        </Card>
       
      </Col>
      <Col md={6} >
        <Card>
          <p>Growth and Earlist Month by Month and Year</p>
          <SingleLinechart/>
        </Card>
      </Col>
    </Row>


    <Row className='mt-5 mb-5'>
      <Col md={6} className='mb-4 mb-md-0'>
        <Card>
          <p>Year Wise Comparision of Surplus/Deficit</p>
          <SingleLinechart/>
        </Card>
       
      </Col>
      <Col md={6} >
        <Card>
          <p>Year Wise Comparision of Achievement</p>
          <SingleLinechart/>
        </Card>
      </Col>
    </Row>


   </Container>
    </div>
  )
}

export default OverallBiz3;