import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';


const SurplusDeficit = () => {
    
const data = {
    labels: ["April", "May", "June", "July", "August", "September", "October", "November", ],
    datasets: [
      {
        label: "Actual",
        data: [33, 53, 85, 41, 44, 65, 100, 68],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      },
      {
        label: "Base",
        data: [33, 25, 35, 51, 65, 76, 30, 40],
        fill: false,
        borderColor: "#742774"
      }
    ]
  };
  return (
    <div>
         <Line data={data} />

    </div>
  )
}

export default SurplusDeficit;